import React from "react";
import { ComponentsSearch } from "./components/ComponentsSearch";

export const Search = () => {

    return(
        <section>
            <ComponentsSearch />
        </section>
    )
}